import React from 'react'
import { useSelector} from "react-redux";

const ProgressCom = () =>
{
    const { progressBarValue } = useSelector((state) => state.portal);

    return <div className={progressBarValue === 100 ? "modal" : "modal is-active"} style={{"zIndex" : 200}}>
                <div className="modal-background"></div>
                <div className="modal-content">
                    <progress className="progress is-info" value={progressBarValue} max={100}></progress>
                </div>
            </div>
}
export default ProgressCom;