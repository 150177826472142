/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import {useSelector,useDispatch} from 'react-redux'
import { setButtonOkLoad, setModalsProps } from '../../../../redux/modals';
import { ApiCall, CurrencyDetails, defultOkButton, getMulValues, pythonUrl, INCOME_TYPE_TAB, getValue, CATALOG_TABLE_TEXT, 
         CATALOG_TABLE_DATE, CATALOG_TABLE_ROYALTY, number_regex, CATALOG_TABLE_TYPES, CATALOG_TABLE_TYPES_ICON, CATALOG_TABLE_DETAILS, CATALOG_TABLE_GROSS, CATALOG_TABLE_NPS  } from '../../../../Data/Variables';
import { useCookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { numberWithCommas } from '../functions';


const TdInput = ({inputData,setInputData,income,year,type,inputTable}) =>
{
    
    return <input className={'input ' + (number_regex.test(getMulValues(inputData,[type,inputTable,income,year],"")) ? "" : "is-danger")} type="text" placeholder='Enter Budget' 
                   onChange={(e) => 
                   {
                        setInputData({...inputData, [type] : {...inputData[type],[inputTable] : {...inputData[type][inputTable],[income] : {...inputData[type][inputTable][income], [year] : e.currentTarget.value}}}}); 
                    }}
                   value={getMulValues(inputData,[type,inputTable,income,year],"")}/>
}

const CatalogInput = ({value,setInputData,inputData,conditionProb,type}) =>
{  

    return  <center>
                <input className={'input '+((conditionProb(getMulValues(inputData,[type,value],""))) ? "" : "is-danger")}  
                       onChange={(e) => 
                        {
                            setInputData({...inputData, [type] : {...inputData[type], [value] : e.currentTarget.value}})
                        }} value={getMulValues(inputData,[type,value],"")} />
            </center>
}
const CatalogMakeTable = ({inputData,setInputData,type,inputTable}) =>
{
    const { theCatalog } = useSelector((state) => state.portal);

    return(
        <>
        <table className="table is-scrollable is-gapless is-striped is-narrow">
            <thead>
                <tr>
                    <th><b>Catalog Name</b></th>
                    {CATALOG_TABLE_TEXT.map((val,i) => 
                    {
                        return <React.Fragment key={i}><th><b>{val}</b></th></React.Fragment>
                    })}
                    {CATALOG_TABLE_DATE.map((val,i) => 
                    {
                        return <React.Fragment key={i}><th><b>{val}</b></th></React.Fragment>
                    })}
                    {CATALOG_TABLE_ROYALTY.map((val,i) => 
                    {
                        return <React.Fragment key={i}><th><b>{val}</b></th></React.Fragment>
                    })}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='td_center_text'><h1 className='subtitle is-5 mr-2' >{theCatalog}</h1></td>
                    {CATALOG_TABLE_TEXT.map((val,i) => 
                    {
                        return <React.Fragment key={i}>
                                <td>
                                    <CatalogInput inputData={inputData} type={type}
                                        setInputData={setInputData} value={val} inputTable={inputTable}
                                        conditionProb={(value) => {return value !== ""}}/>
                                </td>
                                </React.Fragment>
                    })}
                    {CATALOG_TABLE_DATE.map((val,i) => 
                    {
                        return <React.Fragment key={i}>
                                <td>
                            
                                    <DatePicker className={'input ' + (getMulValues(inputData,[type,val],null) === null ? "is-danger" : "")} 
                                        selected={getMulValues(inputData,[type,val],null)} dateFormat="dd-MM-yyyy"
                                        onChange={(data) =>
                                        {
                                            setInputData({...inputData, [type] :{...inputData[type], [val]  : data}})
                                        }}/>
                                </td>
                                </React.Fragment>
                    })}
                    {CATALOG_TABLE_ROYALTY.map((val,i) => 
                    {
                        return <React.Fragment key={i}>
                                <td>
                                    <CatalogInput inputData={inputData} type={type}
                                        setInputData={setInputData} value={val}
                                        conditionProb={(value) => {return /^\d{1,3}(,\d{3})*(\.\d+)?$|^\d+(\.\d+)?$/.test(value)}}/>
                                </td>
                                </React.Fragment>
                    })}
                </tr>
            </tbody>
        </table>
        </>
    );
}
const BudgetMakeTable = ({tableData,inputData,setInputData,type,inputTable}) =>
{

    let th = Object.keys(tableData);
    let tr = Object.values(tableData);
    let thAry = [];
    let trAry = [];

    for(let i=0;i<th.length;i++)
    {
        thAry.push(<React.Fragment key={i}><th className="title is-6">{th[i]}</th></React.Fragment>);
    }
    const thlen = th.length+5;
    for(let i=th.length;i<thlen;i++)
    {
        const year = (parseInt(th[i-1].trim())+1)+" "
        thAry.push(<React.Fragment key={i}><th className="title is-6">{year}</th></React.Fragment>);
        th.push(year)
    }
    
    let y=1;
    let total = {};
    for(;y<tr[0].length;y++)
    {
        let tdAry = [];
        for(let x = 0;x<tr.length;x++)
        {  
            tr[x][y] = tr[x][y]+"";
            tdAry.push(<React.Fragment key={y*tr[0].length+x}><td ><span>{y === tr[0].length-1 ? <b>{tr[x][y]}</b> : tr[x][y]}</span></td></React.Fragment>);
        }
        if (y !== 0)
        {
            const trlen = tr.length+5;
            if(y !== tr[0].length-1)
            {
                for(let x = tr.length;x<trlen;x++)
                {  
                    const income = tr[0][y];
                    const year = th[x];
                    total[x] = getValue(total,x,0) + parseFloat((getMulValues(inputData,[type,inputTable,income,year],"")+"").replace(",",""));
                    tdAry.push(<React.Fragment key={y*tr[0].length+x}>
                        <td>
                            <TdInput inputData={inputData} setInputData={setInputData} income={income} year={year} type={type} inputTable={inputTable}/>
                        </td>
                        </React.Fragment>); 
                }
            }
            else
            {
                for(let x = tr.length;x<trlen;x++)
                {  
                    tdAry.push(<React.Fragment key={y*tr[0].length+x}><td><span >{<b>{numberWithCommas(total[x])}</b>}</span></td></React.Fragment>);
                }
            }
        }

        trAry.push(<React.Fragment key={y}><tr className='tr_hovered'>{tdAry}</tr></React.Fragment>)
    }

   
    return(
        <div className='box'>
            <table className="table is-hoverable is-scrollable is-gapless is-striped is-narrow" style={{"overflowY" : "scroll","overflowX" :"none","padding": "0"}}>
                <thead>
                    <tr>{thAry}</tr>
                </thead>
                <tbody>
                    {trAry}
                </tbody>
            </table>    
        </div>
    );
}
const ModelDuplicate = ({inputData,setInputData,currtab,setCurrTab}) =>
{
    const { modalsProps } = useSelector((state) => state.modals);
    const dispatch = useDispatch();
    return(
        <div className={"modal "+(modalsProps["modelDuplicate"] ? "is-active" : "" )}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                <p className="modal-card-title">Duplicate Data</p>
                <button className="delete" aria-label="close" onClick={() => dispatch(setModalsProps({...modalsProps,"modelDuplicate" : false}))}></button>
                </header>
                <section className="modal-card-body">
                    <h1 className='title is-4'>Are you sure you want to import the data from the other tab to this tab?</h1>
                </section>
                <footer className="modal-card-foot">
                    <div className='fullwidth'>
                        <div className='is-pulled-right'>
                            <button className="button" onClick={() => dispatch(setModalsProps({...modalsProps,"modelDuplicate" : false}))}>Cancel</button>
                            <button className="button is-success" onClick={() => 
                                            {
                                                    dispatch(setModalsProps({...modalsProps,"modelDuplicate" : false}));
                                                    setInputData({...inputData, [CATALOG_TABLE_TYPES[currtab]] : inputData[CATALOG_TABLE_TYPES[1-currtab]]});
                                            } }>Save changes</button>
                        </div>
                    </div>
                </footer>
            </div>
        </div>);
}
const IncomeTab = ({type,inputData,jwt,setInputData,tabledata,currtab,setCurrTab}) =>
{
    const [, setCookies, ] = useCookies();
    const dispatch = useDispatch();
    const { modalsProps } = useSelector((state) => state.modals);
    const downloadBudget = async () =>
    {
        let tempdata = JSON.parse(JSON.stringify(inputData[type]))
        delete tempdata['Total']
        let params = 
        {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
            body: new URLSearchParams(
            {
                "data" : JSON.stringify(tempdata),
                'dicttype' : "budget"
            })
        }
        let data = await ApiCall(pythonUrl+"/Dict2Excel",params,setCookies,(err) => 
        {
            alert("Data Error. Please contact us at data@bitsonic.ai");
        });
        data = await data.blob()
        const blob = new Blob([data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'file.xlsx'; // You can set the file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const uploadBudget = async () =>
    {
        let input = document.createElement('input');
        input.style.visibility = 'hidden';
        input.type = 'file';
        input.onchange = e => 
        { 
            let file = e.target.files[0]; 
            let req = new XMLHttpRequest();
            req.open("POST", pythonUrl+"/Excel2Dict", true);
            req.responseType = 'json';
            let formData = new FormData();
            formData.append('file', file)
            formData.append('dicttype','budget')
            req.withCredentials = false;        
            req.send(formData);

            req.onreadystatechange = () =>
            {
                if (req.readyState === XMLHttpRequest.DONE && req.response !== "NONE")
                {
                    const jsonstring = req.response;
                    setInputData({...inputData,[type] : jsonstring});
                    req.abort();
                }
            }
            req.onerror = () =>
            {
                alert("Data Error. Please contact us at data@bitsonic.ai");
                req.abort();
            }
        }
        input.click();
    }

    return  <center style={{"height" : "100%"}}>
                <ModelDuplicate inputData={inputData} setInputData={setInputData} currtab={currtab} setCurrTab={setCurrTab}/>
                <div className='buttons is-centered mb-0'>
                    <span className='title mr-3 mb-3'>Edit {type.charAt(0).toUpperCase() + type.slice(1)}</span>
                    <button className={"button is-small is-light mr-1 is-primary bordered bordered"}  onClick={(e) => {uploadBudget()}} ><i className="fa-solid fa-upload "></i></button>
                    <button className={"button is-small is-light is-info bordered"}  onClick={(e) => {downloadBudget()}}><i className="fa-solid fa-download"></i></button>
                    <button className={"button is-small is-light is-info bordered"} title='data will imported to this file from the other tab'  onClick={(e) => {
                            dispatch(setModalsProps({...modalsProps,"modelDuplicate" : true}))
                        }}><i className="fa-solid fa-file-import"></i></button>
                </div>
                <div className="is-divider m-0 mb-2"></div>

                {!tabledata || Object.keys(inputData) === 0 ? 
                    <center><div className='loaderCss'/></center> 
                : 
                <div  style={{'overflowY' : 'scroll',"height" : "80%"}}>
                    <CatalogMakeTable inputData={inputData} setInputData={setInputData} type={CATALOG_TABLE_DETAILS}/> 
                    <div className="is-divider"></div>
                    <h1 className='title is-4'>Gross Revenue</h1>
                    <BudgetMakeTable tableData={tabledata} inputData={inputData} setInputData={setInputData} type={type} inputTable={CATALOG_TABLE_GROSS}/>
                    <div className="is-divider"></div>
                    <h1 className='title is-4'>NPS</h1>
                    <BudgetMakeTable tableData={tabledata} inputData={inputData} setInputData={setInputData} type={type} inputTable={CATALOG_TABLE_NPS}/>

                </div>
                }
            </center>
}


const TabComponenet = ({tabname,icon,index,currtab,setCurrTab,isValid}) =>
{
  
  return <li className={currtab === index ? "is-active" : ""}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a className='blacktab' onClick={() => setCurrTab(index)}>
              <span className="icon is-small "><i className={icon}/></span>
              <span>{tabname}</span>
              {isValid ? 
                null
              : <span className='dotWarn has-background-warning'>
                    <center><i className="fa-solid fa-exclamation"/></center>
                </span> }
            </a>
          </li>
}

const validateNumber = (obj) => Object.keys(obj).every(k => typeof obj[k] === "object" ? validateNumber(obj[k]) : number_regex.test(obj[k]));
// const validateNumberPrint = (obj) => Object.keys(obj).map(k => typeof obj[k] === "object" ? validateNumberPrint(obj[k]) : obj[k]);

const ModelBudget = ({jwt,incomeTabPack,clientName}) =>
{
    const { catalogDetails} = useSelector((state) => state.portal);
    const { theCatalog,tabMenuList} = useSelector((state) => state.portal);
    const { modalsProps } = useSelector((state) => state.modals);
    const dispatch = useDispatch();
    const {OrigRenme,tabtofolder} = useSelector((state) => state.globalVars);
    const { outputTitle } = useSelector((state) => state.customOutput);
    const [, setCookies, ] = useCookies();
    const [inputData,setInputData] = useState({});
    const [tabledata,setTableData] = useState(undefined);
    const [currtab,setCurrTab] = useState(0);

    const isNotString = (str) => {return str !== "" && str !== null && str !== undefined}

    const valid_TABLE_TYPES = CATALOG_TABLE_TYPES.map(x => validateNumber(getValue(inputData,x,{"" : ""})));
    const valid_TABLE_TEXT = CATALOG_TABLE_TEXT.map(x => isNotString(getMulValues(inputData,[CATALOG_TABLE_DETAILS,x],undefined)));
    const valid_TABLE_DATE = CATALOG_TABLE_DATE.map(x => isNotString(getMulValues(inputData,[CATALOG_TABLE_DETAILS,x],undefined)));
    const valid_TABLE_ROYALTY = CATALOG_TABLE_ROYALTY.map(x => number_regex.test(getMulValues(inputData,[CATALOG_TABLE_DETAILS,x],undefined)));
    const saveBudget = async () =>
    {
        let params = 
        {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
            body: new URLSearchParams(
            {
                "catalog_id" : catalogDetails[theCatalog]["_id"],
                "data" : JSON.stringify(inputData)
            })
        }
        await ApiCall(pythonUrl+"/SaveBudget",params,setCookies,(err) => 
        {
            alert(err);
            dispatch(setButtonOkLoad(defultOkButton));
        });
        //data = await data.json();
        dispatch(setModalsProps({...modalsProps,"budgetModel" : false}))
    }

    useEffect(() =>
    {
        const get_income = async  () =>
        {
            let params = 
            {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
                body: new URLSearchParams(
                {
                    "catalog_id" : catalogDetails[theCatalog]["_id"],
                    "theTab" : INCOME_TYPE_TAB,
                    "tabKind" :  tabtofolder[OrigRenme[theCatalog][incomeTabPack]],
                    "clientName" : clientName,
                    "currencyMode" : getMulValues(tabMenuList[theCatalog],[CurrencyDetails,"details","currencyMode"],"Fixed"),
                    "currency": 1/outputTitle["currencies"][theCatalog][outputTitle["currencyKey"][theCatalog].toLowerCase()],
                    "currencyKey" : outputTitle["currencyKey"][theCatalog],
                    "yearStatementCurr" : undefined,
                    "yearStatementTarrget" : "Year",
                    "isbudget" : true
                })
            }
            let data = await ApiCall(pythonUrl+"/GetCatalogOutput",params,setCookies,(err) => 
            {
                alert(err);
                dispatch(setButtonOkLoad(defultOkButton));
            });
            data = await data.json();
            setInputData(data[1]);
            setTableData(data[0]);
            // get here incometype sheet
            // totals from bottom and from right side of screen
            // generate reports.
        }
        if(modalsProps['budgetModel'] && Object.keys(inputData).length === 0)
            get_income();
    
    
    },[modalsProps])

    
    return (<div className={modalsProps["budgetModel"] ? "modal is-active" : "modal"} style={{"zIndex" : 30}}>
                <div className="modal-background"></div>
                <div className="modal-card m-0 p-0" style={{"width": "90%","height": "90%"}}>
                    <header className="modal-card-head">
                        <p className="modal-card-title">Budget Settings</p>
                        <button className="delete" onClick={()=>{dispatch(setModalsProps({...modalsProps,"budgetModel" : false}))}}></button>
                    </header>
                    <section className="modal-card-body"  style={{'overflowY' : 'hidden'}}>
                        <div className="tabs is-boxed is-centered is-fullwidth">
                            <ul>
                                {CATALOG_TABLE_TYPES.map((x,i) => 
                                    <React.Fragment key={i}>
                                        <TabComponenet tabname={x.charAt(0).toUpperCase() + x.slice(1)} index={i} currtab={currtab} 
                                                        setCurrTab={setCurrTab} icon={CATALOG_TABLE_TYPES_ICON[i]} isValid={valid_TABLE_TYPES[i]}/>
                                    </React.Fragment>
                                )}
                            </ul>
                        </div>
                            {CATALOG_TABLE_TYPES.map((x,i) => 
                                i === currtab ?
                                    <React.Fragment key={i}>

                                        <IncomeTab  type={x} inputData={inputData} jwt={jwt} setInputData={setInputData} tabledata={tabledata}
                                                    currtab={currtab} setCurrTab={setCurrTab}/>
                                    </React.Fragment>
                                : null
                            )}
                    </section>
                    <footer className="modal-card-foot">
                        <div className='fullwidth'>
                            <div className='is-pulled-right'>
                                <button className="button" onClick={()=>{dispatch(setModalsProps({...modalsProps,"budgetModel" : false}))}}>Cancel</button>
                                <button className="button is-success" onClick={() => {saveBudget()}} 
                                disabled={!valid_TABLE_TYPES.every(x => x) || !valid_TABLE_TEXT.every(x => x) || 
                                          !valid_TABLE_ROYALTY.every(x => x) || !valid_TABLE_DATE.every(x => x)}>Save changes</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>);
}
export default ModelBudget;