import { configureStore } from "@reduxjs/toolkit";
import frameReducer from "./frame";
import portalReducer from "./portal";
import customOutputReducer from "./customOutput";
import filterReducer from "./filter";
import modalsReducer from "./modals";
import makeTableReducer from "./makeTable";
import globalVarsReducer from "./globalVars";
export default configureStore({
    reducer: 
    {
        frame: frameReducer,    
        portal: portalReducer,   
        customOutput: customOutputReducer,     
        filter: filterReducer,     
        modals: modalsReducer,  
        makeTable : makeTableReducer  , 
        globalVars : globalVarsReducer,
    }
});
