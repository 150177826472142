import { createSlice } from "@reduxjs/toolkit";

export const mySlice = createSlice({
  name: "modals",
  initialState:
  {
    filterCustom : false,
    withData : true,
    joinPayors : [[],[],[],[]],
    collection : {},
    period : "",
    concat : [],
    filterOption : false
  },
  reducers: 
  {
    setFilterCustom(state,action){state.filterCustom = action.payload;},
    setWithData(state,action){state.withData = action.payload;},
    setJoinPayors(state,action){state.joinPayors = action.payload;},
    setCollection(state,action){state.collection = action.payload;},
    setPeriod(state,action){state.period = action.payload;},
    setConcat(state,action){state.concat = action.payload;},
    setFilterOption(state,action){state.filterOption = action.payload;},
  }
});

export const {setFilterCustom,setWithData,setJoinPayors,setCollection,setPeriod,setConcat,setFilterOption} = mySlice.actions;
export default mySlice.reducer;
