/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";

import { setProgressBarValue } from '../../redux/portal';
import { setModalsProps } from '../../redux/modals';
import { ApiCall, currUrl, getUserPath, getValue, pythonUrl } from '../../Data/Variables';


const NavBarCom = (props) =>
{
    const { email,version } = useSelector((state) => state.frame);
    const { catalogDetails,theCatalog,tabMenuList,theOutput} = useSelector((state) => state.portal);    
    const { OrigRenme,tabtofolderdraft} = useSelector((state) => state.globalVars);
    const [refreshCatalogs,setRefreshCatalogs] = useState([]);
    const [refreshTime,setRefreshTime] = useState(0);
    const [timeState,setTimeState] = useState(0);
    const { modalsProps} = useSelector((state) => state.modals);
    const dispatch = useDispatch();
    const [, setCookies, removeCookies] = useCookies();
    
    let output = theOutput.split("_");
    let outputName = output.slice(1).join('_')

    useEffect(() => 
    {
        let catalogs = Object.keys(catalogDetails);
        let refreshAry = [];
        let time = 0;
        for(let i=0;i<catalogs.length;i++)
        {
            if(catalogDetails[catalogs[i]]["lastUpdated"] !== undefined && (catalogDetails[catalogs[i]]["UpdateInPortal"] === undefined || (catalogDetails[catalogs[i]]["lastUpdated"] > catalogDetails[catalogs[i]]["UpdateInPortal"])))
            {
                console.log("Refresh "+catalogs[i]+" "+catalogDetails[catalogs[i]]["_id"])
                refreshAry.push(catalogDetails[catalogs[i]]["_id"]);
                let StanTimeValue = parseInt(getValue(catalogDetails[catalogs[i]],"StandardOutputTime",100));
                time += (StanTimeValue*(props.packlens/2))+((StanTimeValue/14)*Object.keys(getValue(tabMenuList[catalogs[i]],"normal",[])).length);
            }
        }    
        setRefreshCatalogs(refreshAry);  
        
        setRefreshTime(time);
    },[catalogDetails])
    const signOut = () =>
    {
        removeCookies("jwt",{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});
        window.location.reload();
    }
    const tick = useRef(); 
    useEffect(()=>
    {
        if (timeState === -1) 
        {
            dispatch(setProgressBarValue(100));
            clearInterval(tick.current);
            setTimeState(0);
        }
        else if(timeState > 0 && timeState !== 100)
            dispatch(setProgressBarValue(timeState));
    })


    const RefreshNewData = async () =>
    {
        dispatch(setProgressBarValue(0));
        
        tick.current = setInterval(() => 
        {
            setTimeState((timeState) => timeState + 1);
        }, refreshTime*10);

        let params = 
        {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : props.jwt},
            body: new URLSearchParams(
            {
                "catalogsToUpdate" : JSON.stringify(refreshCatalogs),
                "userPath" : getUserPath("",email,props.clientName,OrigRenme,theCatalog,outputName,tabtofolderdraft),
                "portalParquet" : "true",
                "clientName" : props.clientName
            })
        }
        await ApiCall(pythonUrl+"/RefreshNewData/NormalOutput",params,setCookies,(err) => 
        {
            console.log(err)
            alert("Data Error. Please contact us at data@bitsonic.ai");
            setTimeState(-1);
        });
        setTimeState(-1);
        setRefreshCatalogs([])
    }


    return (<>
            <div className={modalsProps["modalRefreshData"] ? "modal is-active" : "modal"} style={{"zIndex" : 55}}>
                <div className="modal-background"></div>
                <div className="modal-card" style={{"zIndex" : 55}}>
                    <header className="modal-card-head has-text-centered" >
                        <p className="modal-card-title">
                            This action can take a few hours,<br/>
                            Are you sure you want to do this?
                        </p>
                    </header>
                    <footer className="modal-card-foot buttons is-centered">
                        <button className="button" onClick={()=>{dispatch(setModalsProps({...modalsProps,"modalRefreshData" : false}))}}>No</button>
                        <button className={"button is-success"} onClick={() => {dispatch(setModalsProps({...modalsProps,"modalRefreshData" : false}));RefreshNewData()}}>Yes</button>
                    </footer>
                </div>
            </div>

            <nav className="navbar is-transparent " style={{"zIndex" : 0}}>
                <div className="navbar-brand">
                    <a className="navbar-item">
                        &nbsp;&nbsp;{props.icon !== "" ? <img src={props.icon.substring(0,48)+"/h_75,c_scale/"+props.icon.substring(63)} alt="clientIcon"/> : <></>}
                    </a>
                </div>
                <div className="navbar-menu">
                    <div className="navbar-end">
                        <div className='navbar-item'>
                            {
                            <button className={'button is-small '+ ( refreshCatalogs.length !== 0 ? 'is-warning' : 'is-success')} 
                            title={refreshCatalogs.length === 0 ? "New Data isn't available" : ""} 
                            disabled={refreshCatalogs.length === 0 || true}
                            style={{"zoom" : "80%","position" : "relative"}}
                            onClick={() => dispatch(setModalsProps({...modalsProps,"modalRefreshData" : true}))}>
                                {refreshCatalogs.length !== 0 ? <span title="Badge top right" style={{"zoom" : "135%"}} className="badge is-large is-danger">{refreshCatalogs.length}</span> : null}
                                Refresh New Data
                            </button>
                            }
                        </div>
                        <div className='navbar-item'>
                            <span className='tag is-primary is-light mt-1'  style={{"zoom" : "80%"}}><b className='mr-1'>Last Update:</b>{props.lastUpdate}</span>
                        </div>
                        <div className='navbar-item'>
                            <span className='tag is-info is-light mt-1'  style={{"zoom" : "80%"}}>{version}</span>
                        </div>
                        <div className="navbar-item has-dropdown is-hoverable" style={{"zoom" : "80%"}}>
                            <a className="navbar-link">
                                {email}
                            </a>

                            <div className="navbar-dropdown" style={{"zoom" : "80%"}}>
                                <a className="navbar-item" onClick={() => signOut()}>
                                    Log Out
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                </nav>
        </>);
}
export default NavBarCom;