import { useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { setAllUpTodate } from "../../../../redux/frame";
import { setGenerateTab } from "../../../../redux/makeTable";
import { setModalsProps } from "../../../../redux/modals";
import { setActionToDo, setOutputKind, setProgressBarValue, setTabMenuList, setTheCatalog, setTheOutput } from "../../../../redux/portal";
import { ApiCall, CurrencyDetails, getValue, pythonUrl } from "../../../../Data/Variables";


export const ModalAllOutput = ({clientName,catalogStatus}) =>
{
    const [cookies, setCookies, ] = useCookies();
    const { email } = useSelector((state) => state.frame);
    const { modalsProps } = useSelector((state) => state.modals);
    const { tabMenuList } = useSelector((state) => state.portal);
    
    const { OrigRenme,defualttaboutput } = useSelector((state) => state.globalVars);
    const [buttonLoad,setButtonLoad] = useState(false);
    const dispatch = useDispatch();
    const runalloutput = async () =>
    {
        setButtonLoad(true);
        let params = 
        {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : cookies.jwt},
            body: new URLSearchParams(
            {
                "catalogStatus" : JSON.stringify(catalogStatus),
                "portalParquet" : "true",                
                "redflag" : true,
                "userPath" : email,
                "clientName" : clientName,
            })
        }
        await ApiCall(pythonUrl+"/RefreshNewData/AllOutput",params,setCookies,(err) => 
        {
            console.log(err)
            alert("Data Error. Please contact us at data@bitsonic.ai");
            setButtonLoad(false);
            setProgressBarValue(100);
        });
        const check_interval = async () =>
        {
            params = 
            {
                method: 'GET',
                headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : cookies.jwt},
            }
            
            let res = await ApiCall(pythonUrl+"/RefreshNewData/"+clientName+"/GetStatus",params,setCookies,(err) => 
            {
                console.log(err)
                alert("Data Error. Please contact us at data@bitsonic.ai");
                setButtonLoad(false);
                setProgressBarValue(100);
            });
            res = await res.json();
            dispatch(setProgressBarValue(res.progress));
            if(res.progress === 100)
            {
                let tabName = "Overview"
                dispatch(setTheCatalog(""));
                dispatch(setAllUpTodate(res.alloutput_up_to_date));
                dispatch(setTabMenuList({...tabMenuList,"" : {...tabMenuList[""],[CurrencyDetails] : {...tabMenuList[""][CurrencyDetails],"details" : res.details}}}));
                dispatch(setTheOutput("_"+tabName));
                dispatch(setOutputKind(getValue(defualttaboutput,OrigRenme[""][tabName],"")));
                dispatch(setModalsProps({...modalsProps,"modalAllOutput": false}));
                dispatch(setActionToDo("GetOutput"));    
                setButtonLoad(false);
            }
            else
                setTimeout(check_interval ,5000);
        }
        setTimeout(check_interval ,5000); // 30 sec
    }
    return (<center>
                <div className={modalsProps["modalAllOutput"] ? "modal is-active" : "modal"}>
                <div className="modal-background"></div>
                <div className="modal-card" >
                <header className="modal-card-head">
                    <p className="modal-card-title">All Output Generation</p>
                    <button className="delete" onClick={()=>{dispatch(setModalsProps({...modalsProps,"modalAllOutput" : false}));}}></button>
                </header>
                <section className="modal-card-body">
                   <h1 className="subtitle">
                        All output must be regenerated because there are changes in component catalogues. It will take a couple of <b>hours.</b>
                   </h1>
                   <h1 className="title is-4">
                        Are you sure you want to regenerate?             
                   </h1> 
                </section>
                <footer className="modal-card-foot buttons is-centered">
                    <button className="button is-danger is-light bordered" onClick={()=>{dispatch(setModalsProps({...modalsProps,"modalAllOutput" : false}));}}>Cancel</button>
                    <button className="button is-info is-light bordered" onClick={()=>{
                        dispatch(setTheCatalog(""));
                        dispatch(setModalsProps({...modalsProps,"modalAllOutput" : false}));
                        dispatch(setGenerateTab(true));
                        dispatch(setActionToDo("SetFirstTab"));
                    }}>No, Continue</button>
                    <button className={buttonLoad ? "button is-success is-loading is-light bordered" : "button is-success is-light bordered"} onClick={() => {runalloutput()}}>Yes</button>
                </footer>
                </div>
                </div>
            </center>)
}