/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import Select, { components } from "react-select"
import {SortableContainer, SortableElement,SortableHandle} from "react-sortable-hoc"

import { useDispatch,useSelector} from "react-redux";

import { setOutputTitle,setIsMissingData,setHover } from '../../../../redux/customOutput';
import { setButtonOkLoad,setModalsProps } from '../../../../redux/modals';
import { setTheOutput,setOutputKind,setActionToDo, setTabMenuList } from '../../../../redux/portal';
import { setFilterCustom } from '../../../../redux/filter';

import MakeTable from '../../MakeTableCom'

import tableStruct from '../../../../Images/TableStruct.png'
import { ApiCall, getMulValues, getValue, pythonUrl, readToshort, specialRev, yearAry,firstOutputTitle } from '../../../../Data/Variables';

function arrayMove(array, from, to) 
{
    const slicedArray = array.slice()
    slicedArray.splice(
        to < 0 ? array.length + to : to,
        0,
        slicedArray.splice(from, 1)[0]
    )
    return slicedArray
}
const SortableMultiValue = SortableElement(props =>
{
    const onMouseDown = e => {
        e.preventDefault()
        e.stopPropagation()
    }
    const innerProps = { ...props.innerProps, onMouseDown }
    //selectProps={{...props.selectProps,styles : { menuPortal: base => ({ ...base, zIndex: 47})}}}
    return <components.MultiValue {...props} innerProps={innerProps} />
})
const SortableMultiValueLabel = SortableHandle(props => 
{
    //selectProps={{...props.selectProps,styles : { menuPortal: base => ({ ...base, zIndex: 47})}}}
    return (<components.MultiValueLabel {...props} />)
});
//const SortableSelect = SortableContainer(Select);

const SortableSelect = SortableContainer(Select);

const ModalCustom = ({className,setTheTables,setLoaderGap,setTheLoader,clientName,isPivot,packs,spotiSongList,jwt,setYearStatementTarget,setYearStatement}) =>
{
    const { theCatalog,catalogResFilt,catalogDetails,tabMenuList} = useSelector((state) => state.portal);
    const { modalsProps,buttonOkLoad} = useSelector((state) => state.modals);

    const { filterCustom,joinPayors,collection,period,concat } = useSelector((state) => state.filter);
    const { outputTitle,isMissingData,hover} = useSelector((state) => state.customOutput);
    const { withData} = useSelector((state) => state.filter);
    const { email} = useSelector((state) => state.frame);
    const [, setCookies,] = useCookies();

    const dispatch = useDispatch();
    const [isValid,setValid] = useState(outputTitle["First"].length > 0 && (outputTitle["YearPeriod"] !== "" || specialRev.includes(outputTitle["Royalty"])) &&  outputTitle["Royalty"] !== "" &&   outputTitle["dateStatement"] !== "");
   
    useEffect(() =>
    {
        setValid(outputTitle["First"].length > 0 && (outputTitle["YearPeriod"] !== "" || specialRev.includes(outputTitle["Royalty"])) &&  outputTitle["Royalty"] !== "" &&   outputTitle["dateStatement"] !== "");
    },[outputTitle])

    const genarateOutput = async (jwt) =>
    {
        if (theCatalog !== undefined)
        {
            dispatch(setOutputTitle({...outputTitle,"split" : []}));
            let firstParams = outputTitle["First"].map((val) => val.value);
            dispatch(setButtonOkLoad({...buttonOkLoad,"Generate" : true}));
            let tabName = firstParams.join(" x ") + " x " +(specialRev.includes(outputTitle["Royalty"]) ? "NormalData" : outputTitle["YearPeriod"])+" x "+outputTitle["Royalty"];                
            let count = Object.keys(getMulValues(tabMenuList,[theCatalog,"normal","tabs"],{})).filter(x => x === tabName || x.slice(0,x.indexOf("(")) === tabName).length
            if(count !== 0)
                tabName += "("+count+")";
                
            function raiseError(err,alertMsg,dispatch,setButtonOkLoad)
            {
                console.log(err)
                alert(alertMsg);
                dispatch(setButtonOkLoad({...buttonOkLoad,"Generate" : false}));

            }
            let colValues = Object.values(collection);
        
            let joinFilter = joinPayors.every((val) => val.length > 0);
            let collectionFilter = colValues.length > 0 && colValues[0].length > 0
           
            let filter =  (((collectionFilter) || (joinFilter) || period !== "" || concat.length > 1 || 

            ((!catalogResFilt[theCatalog].every(element => {return  element !== undefined ? Object.keys(element).length === 0 || Object.values(element).length === 0: true}))))
            && filterCustom ? JSON.stringify(catalogResFilt[theCatalog]) : "") ;
            
            let params = 
            {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
                body: new URLSearchParams(
                {
                    "catalog_id" : catalogDetails[theCatalog]["_id"],
                    "dateStatement" : outputTitle["dateStatement"],
                    "joinPayors" :  filterCustom && joinFilter ? JSON.stringify(joinPayors) : JSON.stringify([[],[],[],[]]),
                    "clientName" : clientName,
                    "collectionFilter"  : filterCustom && collectionFilter > 0 ? JSON.stringify(collection) : "{}",
                    "filterBy"  : filter,
                    "withData" : withData ? "" : "not",
                    "userPath": email,
                    "catalogName" : theCatalog,
                    "currencyValue" : 1/outputTitle["currencies"][theCatalog][outputTitle["currencyKey"][theCatalog].toLowerCase()],
                    "yearParam" : specialRev.includes(outputTitle["Royalty"]) ? "NormalData" : outputTitle["YearPeriod"],
                    "yearStatementMethod" : outputTitle["yearStatementMethod"] === "" ? "WithData" :  outputTitle["yearStatementMethod"],
                    "tabName" : tabName,
                    "firstTitles" : JSON.stringify(firstParams),
                    "byTitle" : outputTitle["Royalty"],
                    "theyear" : outputTitle["theYear"],
                })
            }
            let myData = await ApiCall(pythonUrl+"/GenerateSingleOutput",params,setCookies,(err) => 
            {
                raiseError(err,"One of the inputs is unavailable for this Catalogue. Please contact data@bitsonic.ai if you believe this is an error.",dispatch,setButtonOkLoad);
            });
            myData = await myData.json();
            if(myData["resMsg"] === "dfEmpty" || "failed" in myData)
                raiseError("","The filter you are looking for is giving an empty result, Please contact us at data@bitsonic.ai",dispatch,setButtonOkLoad)
            else
            {
                let preProps = {...getMulValues(tabMenuList[theCatalog],["normal","tabs"],{})};
                preProps[tabName] = myData.details;
                dispatch(setTabMenuList({...tabMenuList,[theCatalog] : {...tabMenuList[theCatalog],"normal" : {...tabMenuList[theCatalog]["normal"],"tabs" : preProps}}}));
                let data = myData.data;
                
                dispatch(setTheOutput(theCatalog+"_"+tabName));
                dispatch(setOutputKind(""))
                dispatch(setActionToDo("AddOutput"));
                setTheTables(<></>);
                setLoaderGap(true);
                let tables = []
                for(let i=0;i<data.length;i++)
                {
                    tables.push(<React.Fragment key={i}><MakeTable index={i} tableLen={data.length} setLoaderGap={setLoaderGap} clientName={clientName} spotiSongList={spotiSongList} isPivot={isPivot} dataDir={theCatalog !== undefined ? catalogDetails[theCatalog]["_id"] : ""} tableData={data[i]} tableName={tabName} outputKind={""} jwt={jwt}/></React.Fragment>);
                }
                setTheTables(tables);
                setYearStatementTarget(preProps[tabName]["YearPeriodInterval"]);
                setYearStatement(preProps[tabName]["YearPeriodInterval"]);
                dispatch(setButtonOkLoad({...buttonOkLoad,"Generate" : false}));
                dispatch(setModalsProps({...modalsProps,"modalCustom":false,"modalFilter" : false}));
                dispatch(setFilterCustom(false));
                dispatch(setOutputTitle({...outputTitle,...firstOutputTitle}));
                dispatch(setIsMissingData(false));
            }
        }
        else
            alert("Choose Catalog")
    }

    //Select
    const onChange = (dict) => dispatch(setOutputTitle({...outputTitle,"First": dict}));
    const onSortEnd = ({ oldIndex, newIndex }) => 
    {
        const newValue = arrayMove(outputTitle["First"], oldIndex, newIndex)
        dispatch(setOutputTitle({...outputTitle,"First": newValue}));
    }

    //

    return (<>{
        modalsProps["modalCustom"] ?
            <>
                <div className = { modalsProps["modalCustom"] ? "modal is-active" : "modal" } style={hover === 1  ||  filterCustom ? {"left" : "-56%","position" : "absolute","zIndex" : 31} : {"zIndex" : 45}} >
                    {hover === 1 ?
                    <div  style={{"position" : "absolute","top" : "3%","right" : "3%","zIndex" : 45,"width" : "33%","height" : "100%"}}>
                        <img src={tableStruct}  style={{"height" : "91%","width" : "100%"}} alt="table structure"/>
                    </div>
                    :
                        <></>
                    }
                <div className='modal-background' />
                    <div className="modal-card" style={hover === 1  ||  filterCustom ? {"position" : "absolute","zIndex" : 45,"width" : "26%"} : {}}>
                        <header className="modal-card-head">
                            <p className="modal-card-title is-centered">Generate Output</p>
                            <button className="delete" onClick={() => {dispatch(setModalsProps({...modalsProps,"modalCustom" : false,"modalFilter" : false}));dispatch(setFilterCustom(false));dispatch(setOutputTitle({...outputTitle,...firstOutputTitle}));dispatch(setHover(0));}}></button>
                        </header>
                        <section className="modal-card-body" >
                            <div className='mb-2'>
                                <center>
                                    <p className='is-size-6'>First Parameter 
                                        <a onClick={() =>  {if(hover === 1) dispatch(setHover(0));
                                                            else {dispatch(setHover(1));dispatch(setFilterCustom(false));dispatch(setModalsProps({...modalsProps,"modalFilter" : false}))}}} ><span className='icon has-text-info'><i className="fas fa-circle-info" /></span></a>
                                    </p>
                                    <div className="is-small" >
                                        <SortableSelect onChange={onChange} axis="x" useDragHandle  helperclassName={'sortableHelper'} value={outputTitle["First"]}
                                        menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 47,textAlign: "center" }) }} 
                                        onSortEnd={onSortEnd} distance={4} getHelperDimensions={({ node }) => node.getBoundingClientRect()} 
                                        components={{MultiValue: SortableMultiValue,MultiValueLabel: SortableMultiValueLabel}} options={
                                        [
                                            { value: getValue(readToshort,"Song Name","Song Name")       , label: 'Song Name' },
                                            { value: getValue(readToshort,"Income Type","Income Type")  , label: 'Income Type' },
                                            { value: getValue(readToshort,"Payor","Payor")  , label: 'Payor' },
                                            { value: getValue(readToshort,"Source","Source")  , label: 'Source' },
                                            { value: getValue(readToshort,"Territory","Territory")  , label: 'Territory' },
                                            { value: getValue(readToshort,"Date","Date")  , label: 'Date' },
                                            { value: getValue(readToshort,"Release Year","Release Year")  , label: "Release Year"},
                                            { value: getValue(readToshort,"Song Share %","Song Share %")  , label: "Song Share %"},
                                            { value: getValue(readToshort,"Release Artist","Release Artist")  , label: 'Release Artist' },
                                            { value: getValue(readToshort,"Product","Product")  , label: 'Product' },
                                            { value: getValue(readToshort,"Income Type II","Income Type II")  , label: 'Income Type II' },
                                            { value: getValue(readToshort,"Payor Contract Number","Payor Contract Number")  , label: "Payor Contract Number"},
                                            { value: getValue(readToshort,"Production Title","Production Title")  , label: "Production Title"},
                                            { value: getValue(readToshort,"Episode Title","Episode Title")  , label: "Episode Title"},
                                            { value: getValue(readToshort,"Episode Release Date","Episode Release Date")  , label: "Episode Release Date"},
                                            { value: getValue(readToshort,"Media Release Date","Media Release Date")  , label: "Media Release Date"},
                                            { value: getValue(readToshort,"Media Air End Date","Media Air End Date")  , label: "Media Air End Date"},
                                            {...(theCatalog === "" ? { value: getValue(readToshort,"Catalog","Catalog")  , label: "Catalog"} : {})} ,

                                        ]} isMulti closeMenuOnSelect={false}/>
                                    </div>
                                </center>
                            </div>
                            <div className='mb-2'>
                                <center>
                                    <p className='is-size-6'>Year Period Interval</p>
                                    <div className={outputTitle["YearPeriod"] === "" ? "select is-small is-danger" : (isMissingData ? "select is-small is-warning" : "select is-small")} style={{"zIndex" : 45}}>
                                        <select onChange={(e)  => 
                                            {
                                                dispatch(setIsMissingData(theCatalog !== undefined && yearAry.slice(yearAry.indexOf(catalogDetails[theCatalog]["periodInterval"])+1).includes( e.currentTarget.value)))
                                                dispatch(setOutputTitle({...outputTitle,"YearPeriod" : e.currentTarget.value}));     
                                                dispatch(setModalsProps({...modalsProps,"modalWarningVar" : true}))                                             
                                            }} value={specialRev.includes(outputTitle["Royalty"]) ? "NormalData" : outputTitle["YearPeriod"]}>

                                            <option value="">Select...</option>
                                            <option value="NormalData">Normal Data</option>
                                            <option value="Half">Half</option>
                                            <option value="Quarter" >Quarter</option>
                                            <option value="Month" >Month</option>
                                            <option value="Year" >Year</option>
                                        </select>
                                    </div>
                                </center>
                            </div>
                            <div className='mb-2'>
                                <center>
                                    <p className='is-size-6'>Royalty</p>
                                    <div className={outputTitle["Royalty"] === "" ? "select is-small is-danger" : "select is-small"} style={{"zIndex" : 45}}>
                                        <select onChange={(e) => {dispatch(setOutputTitle({...outputTitle,"Royalty" : e.currentTarget.value}))}}>
                                            <option value="">Select...</option>
                                            <option value="Rev">NET Revenue</option>
                                            <option value="Bonus">Bonus</option>
                                            <option value="Gross">GROSS Revenue</option>
                                            <option value="Plays">Plays Revenue</option>
                                      
                                            {/*
                                            <option value="Share">Share Revenue</option>
                                            <option value="Recieved">Percent Recieved Revenue</option>
                                            <option value="Control">Control Revenue</option>
                                            */}
                                         
                                        </select>
                                    </div>
                                </center>
                            </div>
                            <div className='mb-2'>
                                <center>
                                    <p className='is-size-6'>Date</p>
                                    <div className={outputTitle["dateStatement"] === "" ? "select is-small is-danger" : "select is-small"} style={{"zIndex" : 45}}>
                                        <select onChange={(e) => {dispatch(setOutputTitle({...outputTitle,"dateStatement" : e.currentTarget.value}))}}>
                                            <option value="">Select...</option>
                                            <option value="Statement">Statement</option>
                                            {  packs.includes("Standard Cash Date")
                                            || packs.includes("Sync Cash Date") 
                                            || packs.includes("Cash Date Outputs") || theCatalog === "" ? <option value="distribution">Cash Date</option> : null}
                                        </select>
                                    </div>
                                </center>
                            </div>
                            <div className='mb-4'>
                                <center>
                                    <p className='is-size-6'>From Year</p>
                                    <div className='select is-small ' style={{"zIndex" : 45}}>
                                        <select onChange={(e) => {dispatch(setOutputTitle({...outputTitle,"theYear" : e.currentTarget.value}))}}>
                                            <option value="1" >All Years - Now</option>
                                            <option value="2022" >From 2022 - Now</option>
                                            <option value="2021" >From 2021 - Now</option>
                                            <option value="2020" >From 2020 - Now</option>
                                            <option value="2019" >From 2019 - Now</option>
                                            <option value="2018" >From 2018 - Now</option>
                                            <option value="2017" >From 2017 - Now</option>
                                            <option value="2016" >From 2016 - Now</option>
                                            <option value="2015" >From 2015 - Now</option>
                                            <option value="2014" >From 2014 - Now</option>
                                            <option value="2013" >From 2013 - Now</option>
                                            <option value="2012" >From 2012 - Now</option>
                                        </select>
                                    </div>
                                </center>
                            </div>
                            <div className='mb-4'>
                                <center>
                                    <div className="field">
                                        <input id="switchExample1" type="checkbox" className="switch" checked={filterCustom} onChange={() => {dispatch(setModalsProps({...modalsProps,"modalFilter" : !filterCustom}));dispatch(setFilterCustom(!filterCustom));dispatch(setHover(0))}}/>
                                        <label htmlFor="switchExample1">Filter</label>
                                    </div>
                                </center>
                            </div>

                        </section>
                        <footer className="modal-card-foot buttons is-centered">
                            <button className={"button"} onClick={() => {dispatch(setFilterCustom(false));dispatch(setOutputTitle({...outputTitle,...firstOutputTitle}));dispatch(setModalsProps({...modalsProps,"modalFilter" : false,"modalCustom":false}));dispatch(setHover(0));dispatch(setIsMissingData(false))}}>Cancel</button>
                            <button className={buttonOkLoad["Generate"] ? 'button is-info is-loading' : "button is-info"} onClick={() => {isValid ? genarateOutput(jwt) : alert("Please Fill "+( outputTitle["First"].length === 0 ? "\nFirst Paraneter" : "")+( outputTitle["YearPeriod"] === "" ? "\nYear Period Interval" : "")+( outputTitle["Royalty"] === "" ? "\nRoyalty" : "")+( outputTitle["dateStatement"] === "" ? "\nDate" : "")+"\n\nFields In")}}>Ok</button>
                        </footer>
                    </div>
                </div>
            </>
        :   
            <>
            </>  
        }</>);
}
export default ModalCustom;