/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useDispatch,useSelector} from "react-redux";

import { setModalsProps,setButtonOkLoad } from '../../../../redux/modals';
import { setPropsOutput,setOutputTitle } from '../../../../redux/customOutput';
import {setActionToDo, setOutputKind, setProgressBarValue, 
        setTabMenuList, setTheOutput} from '../../../../redux/portal';

import currencies from '../../../../Data/currencies'
import { mongoNodeApiCall } from '../functions';
import { setAllUpTodate } from '../../../../redux/frame';
import { ApiCall, CurrencyDetails, getMulValues, getValue, pythonUrl } from '../../../../Data/Variables';

const FixedCurrRateTab = () =>
{
    const dispatch = useDispatch();
    const { propsOutput } = useSelector((state) => state.customOutput);
    const { theCatalog } = useSelector((state) => state.portal);
    return (
            <div>
                <div className="field is-grouped is-grouped-multiline is-grouped-centered">
                    {currencies.map((cur,index)=> 
                    {return(
                        <React.Fragment key={index}>
                            {    console.log(cur.currency.toUpperCase(),propsOutput["currencies"][theCatalog])
}
                            <div className='box field is-horizontal mr-2 mb-3'>
                                <div className='field-label is-normal'><label className="label">{cur.currency.toUpperCase()}</label></div>
                                <p className="control has-icons-left">
                                    <input className="input" type="number" step="any" placeholder="Currency value" value={Object.keys(propsOutput["currencies"][theCatalog]).length === 0 ? 0 : propsOutput["currencies"][theCatalog][cur.currency]} onChange={(e) => {dispatch(setPropsOutput({...propsOutput,"currencies" : {...propsOutput["currencies"], [theCatalog] : {...propsOutput["currencies"][theCatalog],[cur.currency] : parseFloat(e.currentTarget.value)}}}))}}/>
                                    <span className="icon is-small is-left">{cur.symbol}</span>
                                </p>
                            </div>
                        </React.Fragment>)
                    })}
                </div>
            </div>
    );
}
const ExchangeInput = ({index,value,data,title,setCurrRates}) =>
{
    const onChange = (e) =>
    {
        let dataTemp = {...data,[title] : [...data[title]]};
        dataTemp[title][index] = parseFloat(e.currentTarget.value);
        if(!isNaN(parseFloat(e.currentTarget.value)))
            setCurrRates(dataTemp);
    }
    return (<input className='input' type={"number"} value={value} onChange={onChange}/>)
}
const MakeTable = ({tableData,setCurrRates}) =>
{
    const [thAry,setThAry] = useState([]);
    const [trAry,setTrAry] = useState([]);
    const { theCatalog } = useSelector((state) => state.portal);
    const { propsOutput } = useSelector((state) => state.customOutput);

    useEffect(() => 
    {
        let th = Object.keys(tableData);
        let tr = Object.values(tableData);
        let thAryTemp = [];
        let trAryTemp = [];
        if(tr !== undefined && tr.length > 0)
        {
            for(let i=0;i<th.length;i++)
            {
                if(th[i] === "Output Exchange Rate" )
                    th[i] = th[i] +" ("+propsOutput["currencyKey"][theCatalog]+")"
                thAryTemp.push(<React.Fragment key={i}><th className="title is-6">{th[i]}</th></React.Fragment>);
            }
            for(let y=0;y<tr[0].length;y++)
            {
                let tdAry = [];
                for(let x = 0;x<tr.length;x++)
                {  
                    if(th[x] === "Exchange Rate Usd")
                        tdAry.push(<React.Fragment key={y*tr[0].length+x}><td><ExchangeInput index={y} title={"Exchange Rate Usd"} value={tr[x][y]} data={tableData} setCurrRates={setCurrRates}/></td></React.Fragment>);
                    else if(th[x] === "Output Exchange Rate ("+propsOutput["currencyKey"][theCatalog]+")")
                        tdAry.push(<React.Fragment key={y*tr[0].length+x}><td><ExchangeInput index={y} title={"Output Exchange Rate"} value={tr[x][y]} data={tableData} setCurrRates={setCurrRates}/></td></React.Fragment>);
                    else if(th[x] === "Currency")
                        tdAry.push(<React.Fragment key={y*tr[0].length+x}><td ><span className={"tag is-primary is-light"}>{tr[x][y]}</span></td></React.Fragment>);
                    else if(th[x] === "Payor")
                        tdAry.push(<React.Fragment key={y*tr[0].length+x}><td ><span className={"tag is-link is-light"}>{tr[x][y]}</span></td></React.Fragment>);
                    else
                        tdAry.push(<React.Fragment key={y*tr[0].length+x}><td ><span className={""}>{tr[x][y]}</span></td></React.Fragment>);
                }
                trAryTemp.push(<React.Fragment key={y}><tr className='tr_hovered'>{tdAry}</tr></React.Fragment>)
            }
        }
        setTrAry(trAryTemp);
        setThAry(thAryTemp)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tableData,propsOutput])
    return(
        <table className="table is-hoverable is-scrollable is-gapless is-striped is-narrow has-sticky-header" style={{"overflowY" : "scroll","overflowX" :"none","padding": "0"}}>
            <thead>
                <tr className='tr_hovered'>
                     {thAry}
                </tr>
            </thead>
            <tbody>
                {trAry}
            </tbody>
        </table>
    );
}
const AutoCurrRateTab = ({saveChanges,currRates,setCurrRates}) =>
{
    const [btnLoadAuto,setBtnLoadAuto] = useState(["","",""]);
    const [isOnceDownload,setIsOnceDownload] = useState(false);

    const [cookies, setCookies, ] = useCookies();
    const { theCatalog,tabMenuList } = useSelector((state) => state.portal);
    const downloadExcel = async () =>
    {          
        setBtnLoadAuto([btnLoadAuto[0],btnLoadAuto[1],"is-loading"])
        let params = 
        {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : cookies.jwt},
            body: new URLSearchParams(
            {
                "data" : JSON.stringify(getMulValues(tabMenuList[theCatalog],[CurrencyDetails,"details","currencyRates"],undefined))
            })
        }
        let data = await ApiCall(pythonUrl+"/Dict2Excel",params,setCookies,(err) => 
        {
            console.log(err)
            alert("Data Error. Please contact us at data@bitsonic.ai");
            setBtnLoadAuto([btnLoadAuto[0],btnLoadAuto[1],""])
        });
        setBtnLoadAuto([btnLoadAuto[0],btnLoadAuto[1],""])
        data = await data.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(data);
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    const uploadExcel = async () =>
    {          
        setBtnLoadAuto([btnLoadAuto[0],"is-loading",btnLoadAuto[2]]);
        let input = document.createElement('input');
        input.type = 'file';

        input.onchange = async e => 
        { 
            let file = e.target.files[0];
            if(file !== undefined && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            {
                let formData = new FormData();
                formData.append("file",file);
                let params = 
                {
                    method: 'POST',
                    headers: {"Authorization" : cookies.jwt},
                    body: formData
                }
                let data = await ApiCall(pythonUrl+"/Excel2Dict",params,setCookies,(err) => 
                {
                    console.log(err)
                    alert("Data Error. Please contact us at data@bitsonic.ai");
                    setBtnLoadAuto([btnLoadAuto[0],"",btnLoadAuto[2]])
                });
                data = await data.json();
                const insideData = getMulValues(tabMenuList[theCatalog],[CurrencyDetails,"details","currencyRates"],undefined);
                if(insideData !== undefined && JSON.stringify(Object.keys(data)) === JSON.stringify(Object.keys(insideData)) && Object.values(data).length === Object.values(insideData).length)
                    setCurrRates({...data});
                else
                    alert("upload file failed")
            }
            else
                alert("upload file failed")

            setBtnLoadAuto([btnLoadAuto[0],"",btnLoadAuto[2]])
        }
        input.oncancel = () => { setBtnLoadAuto([btnLoadAuto[0],"",btnLoadAuto[2]])}
        input.click();

    }
    return(<>
                <div className='columns is-centered is-vcentered'>
                    <div className='column is-narrow  m-2 p-0'>
                        <center className='m-2'>
                            <button className={'button is-danger is-light '+btnLoadAuto[0]} onClick={() => saveChanges(true)}>Click for automatic exchange rates by dates</button>
                        </center>
                    </div>
                    <div className='column is-narrow m-0 p-0'>
                        <button className={"button is-info is-light "+btnLoadAuto[2]}  onClick={(e) => {downloadExcel();setIsOnceDownload(true)}} disabled={Object.keys(getMulValues(tabMenuList[theCatalog],[CurrencyDetails,"details","currencyRates"],{})).length <= 0} ><i className="fa-solid fa-download"/></button>
                        <button className={"button mr-1 is-primary is-light "+btnLoadAuto[1]}  onClick={(e) => {uploadExcel()}} disabled={!isOnceDownload || Object.keys(getMulValues(tabMenuList[theCatalog],[CurrencyDetails,"details","currencyRates"],{})).length <= 0} ><i className="fa-solid fa-upload"/></button>
                    </div>
                </div>
                <div className='table-container' style={{"height" : "66%","overflow" : "scroll"}}>
                    <MakeTable tableData={currRates} setCurrRates={setCurrRates}/>
                </div>
            </>)
}
const ModalCurrency = ({clientName,packs}) =>
{
    const dispatch = useDispatch();

    const { theCatalog,tabMenuList,catalogDetails } = useSelector((state) => state.portal);
    const { propsOutput,outputTitle } = useSelector((state) => state.customOutput);
    const { modalsProps,buttonOkLoad } = useSelector((state) => state.modals);
    const {OrigRenme,defualttaboutput} = useSelector((state) => state.globalVars);
    const { email } = useSelector((state) => state.frame);
    const [isCurrencyChanged,setCurrencyChanged] = useState(theCatalog !== undefined ? JSON.stringify(propsOutput["currencies"][theCatalog]) === JSON.stringify(outputTitle["currencies"][theCatalog]) : false);
    const [currTab,setCurrTab] = useState(theCatalog !== "" && getMulValues(tabMenuList[theCatalog],[CurrencyDetails,"details","currencyMode"],"Fixed") === "Fixed" ? 0 : 1);
    const [currRates,setCurrRates] = useState(getMulValues(tabMenuList[theCatalog],[CurrencyDetails,"details","currencyRates"],{}));
    const [cookies, setCookies, ] = useCookies();
    useEffect(()=> {setCurrencyChanged(theCatalog !== undefined ? JSON.stringify(propsOutput["currencies"][theCatalog]) !== JSON.stringify(outputTitle["currencies"][theCatalog]) : false);},[theCatalog,propsOutput,outputTitle])
    useEffect(() => {
                        setCurrRates(getMulValues(tabMenuList[theCatalog],[CurrencyDetails,"details","currencyRates"],{}));
                        setCurrTab(theCatalog !== "" && getMulValues(tabMenuList[theCatalog],[CurrencyDetails,"details","currencyMode"],"Fixed") === "Fixed" ? 0 : 1);
                    },[modalsProps["modalCurrency"]])    

    const [timeState,setTimeState] = useState(0);

    const tick = useRef(); 
    useEffect(()=>
    {
        if (timeState === -1) 
        {
            dispatch(setProgressBarValue(100));
            clearInterval(tick.current);
            setTimeState(0);
        }
        else if(timeState > 0 && timeState !== 100)
            dispatch(setProgressBarValue(timeState));
    },[timeState])
    const saveChanges = async (auto) =>
    {
        let fetchCurrency = true;
        let currencyMode = "Fixed";
        let exchange_rates = {}; 
        if(auto)
        {
           currencyMode = "Auto"
           mongoNodeApiCall("project", "update", {"projectString" : JSON.stringify({"outputCurrency" : propsOutput["currencyKey"][theCatalog], "_id": catalogDetails[theCatalog]["_id"]})});
        }
        else if((currTab === 1 && (((JSON.stringify(currRates) !== JSON.stringify(getMulValues(tabMenuList[theCatalog][CurrencyDetails],["details","currencyRates"],{})))) || propsOutput["currencyKey"][theCatalog] !== outputTitle["currencyKey"][theCatalog])))
           currencyMode = "Custom";
        else if((!isCurrencyChanged && currTab === 0 ) || (currTab === 1))
        {
           fetchCurrency = false;
           dispatch(setButtonOkLoad({...buttonOkLoad,"GetOutput" : true})); 
           dispatch(setActionToDo("GetOutputState"));
           dispatch(setOutputTitle({...outputTitle,"currencyKey" : propsOutput["currencyKey"]}));
        }

        if(fetchCurrency)
        {
            dispatch(setOutputTitle({...outputTitle,"currencies" : propsOutput["currencies"],"currencyKey" : propsOutput["currencyKey"]}));
            dispatch(setButtonOkLoad({...buttonOkLoad,"GetOutput" : true})); 
            dispatch(setProgressBarValue(0));
            if(currencyMode === "Fixed")
            {
                mongoNodeApiCall("project", "update", {"projectString" : JSON.stringify({"currency" : propsOutput["currencies"][theCatalog], "_id": catalogDetails[theCatalog]["_id"]})});
                Object.keys(getValue(propsOutput["currencies"],theCatalog,{})).forEach((key) => exchange_rates[key.toUpperCase()] = propsOutput["currencies"][theCatalog][key]);
            }
            
            let params = 
            {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : cookies.jwt},
                body: new URLSearchParams(
                {
                    "catalog_id" : catalogDetails[theCatalog]["_id"],
                    "AllOutput" : theCatalog === "" ? true : false,
                    "clientName" : clientName,
                    "exchange_rates": currencyMode === "Fixed" ? JSON.stringify(exchange_rates) : JSON.stringify(currRates) ,
                    "currencyKey" : propsOutput["currencyKey"][theCatalog],
                    "currencyMode" : currencyMode
                })
            }
            let rasp = await ApiCall(pythonUrl+"/CurrencyOptimize",params,setCookies,(err) => 
            {
                console.log(err);
                setTimeState(-1);
                dispatch(setButtonOkLoad({...buttonOkLoad,"GetOutput" : false})); 
                alert("Data Error. Please contact us at data@bitsonic.ai");
            });
            rasp = await rasp.json();
            dispatch(setAllUpTodate(rasp.alloutput_up_to_date));
            dispatch(setTabMenuList({...tabMenuList,[theCatalog] : {...tabMenuList[theCatalog],[CurrencyDetails] : {...tabMenuList[theCatalog][CurrencyDetails],"details" : rasp.details}}}));
            if(rasp["result"] === "success")
            {   
                if(theCatalog === "")
                {
                    params = 
                    {
                        method: 'POST',
                        headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : cookies.jwt},
                        body: new URLSearchParams(
                        {
                            "portalParquet" : "true",
                            "userPath" : email,
                            "clientName" : clientName
                        })
                    }
                    await ApiCall(pythonUrl+"/RefreshNewData/AllOutput",params,setCookies,(err) => 
                    {
                        console.log(err)
                        alert("Data Error. Please contact us at data@bitsonic.ai");
                        setTimeState(-1);
                        dispatch(setButtonOkLoad({...buttonOkLoad,"GetOutput" : false})); 
                    });
                    const check_interval = async () =>
                    {
                        params = 
                        {
                            method: 'GET',
                            headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : cookies.jwt},
                        }
                        
                        let res = await ApiCall(pythonUrl+"/RefreshNewData/"+clientName+"/GetStatus",params,setCookies,(err) => 
                        {
                            console.log(err)
                            alert("Data Error. Please contact us at data@bitsonic.ai");
                            setTimeState(-1);
                            dispatch(setButtonOkLoad({...buttonOkLoad,"GetOutput" : false})); 
                        });
                        res = await res.json();
                        dispatch(setProgressBarValue(res.progress));
                        if(res.progress === 100)
                        {
                            let tabName = "Overview"
                            dispatch(setTheOutput(theCatalog+"_"+tabName));
                            dispatch(setOutputKind(getValue(defualttaboutput,OrigRenme[theCatalog][tabName],"")));
                            dispatch(setModalsProps({...modalsProps,"modalCustom":false,"modalGenerate" : false}));
                            dispatch(setActionToDo("GetOutput"));    
                        }
                        else
                            setTimeout(check_interval ,5000);
                    }
                    setTimeout(check_interval ,5000); // 30 sec
                }
                else
                {
                    let StanTimeValue = parseInt(getValue(catalogDetails[theCatalog],"StandardOutputTime",100));
                    let refreshTime = (StanTimeValue*(packs.length/2))+((StanTimeValue/14)*Object.keys(getValue(tabMenuList[theCatalog],"normal",[])).length)+12*3;
                    tick.current = setInterval(() => { setTimeState((timeState) => timeState + 1); }, refreshTime*10);
                    params = 
                    {
                        method: 'POST',
                        headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : cookies.jwt},
                        body: new URLSearchParams(
                        {
                            "catalogsToUpdate" : JSON.stringify([catalogDetails[theCatalog]["_id"]]),
                            "portalParquet" : "true",
                            "userPath" : email,
                            "clientName" : clientName
                        })
                    }
                    await ApiCall(pythonUrl+"/RefreshNewData/NormalOutput",params,setCookies,(err) => 
                    {
                        console.log(err)
                        alert("Data Error. Please contact us at data@bitsonic.ai");
                        setTimeState(-1);
                        dispatch(setButtonOkLoad({...buttonOkLoad,"GetOutput" : false})); 
                    });
                    dispatch(setButtonOkLoad({...buttonOkLoad,"GetOutput" : false})); 
                    setTimeState(-1);

                    let tabName = "Overview"
                    dispatch(setTheOutput(theCatalog+"_"+tabName));
                    dispatch(setOutputKind(getValue(defualttaboutput,OrigRenme[theCatalog][tabName],"")));
                    dispatch(setModalsProps({...modalsProps,"modalCustom":false,"modalGenerate" : false}));
                    dispatch(setActionToDo("GetOutput"));    
                }
            }
        }
    }

    return (<>
    {modalsProps["modalCurrency"]
    ?
        <div className={modalsProps["modalCurrency"] ? "modal is-active" : "modal"} style={{"zIndex" : 25}}>
        <div className="modal-background"></div>
        <div className="modal-card" style={{"width" : "80%"}}>
            <header className="modal-card-head">
                <p className="modal-card-title">Output Currency Settings</p>
                <button className="delete" onClick={()=>{dispatch(setModalsProps({...modalsProps,"modalCurrency" : false}));}}>{/*Cancel*/}</button>
            </header>
            <section className="modal-card-body" style={{"padding" : "0px"}}>
            <div className="tabs is-boxed is-centered m-2 is-fullwidth">
                <ul>
                    <li className={(currTab === 0 ? "is-active" : "")+ (theCatalog === "" ? " disabledButtons" : "")}>
                        <a onClick={() => setCurrTab(0)} ><span className="icon is-small"><i className="fa-solid fa-wrench"/></span><span>Fixed Currency Rate</span></a>
                    </li>
                    <li className={currTab === 1 ? "is-active" : ""}>
                        <a onClick={() => setCurrTab(1)}><span className="icon is-small"><i className="fa-solid fa-wand-sparkles"/></span><span>Custom/Auto Currency Rate</span></a>
                    </li>
                </ul>
            </div>
            <div className='column fullHeight'>
                <p className='title is-4 is-marginless mdr-2'>Exchange Rates: Value in {currTab === 0 ? "USD" : propsOutput["currencyKey"][theCatalog]}.</p>
                <div className='column m-1 p-0'>
                    <div>
                        <p className='is-size-6'>Output Currency:</p>
                        <div className='select'>
                                <select onChange={(e) => {dispatch(setPropsOutput({...propsOutput,"currencyKey" : {...propsOutput["currencyKey"], [theCatalog] : e.currentTarget.value}}));}} value={propsOutput["currencyKey"][theCatalog]}>
                                    {currencies.map((cur,index)=> <React.Fragment key={index}>{<option value={cur.currency.toUpperCase()}>{cur.currency.toUpperCase()}</option>}</React.Fragment>)}
                                </select>
                        </div>&nbsp;
                    </div>
                </div>
                {currTab === 0  ? <FixedCurrRateTab /> :
                (currTab === 1 ? <AutoCurrRateTab saveChanges={saveChanges} currRates={currRates} setCurrRates={setCurrRates}/> : <></>)}
            </div>
            </section>
            <footer className="modal-card-foot buttons is-centered">
                <button className="button" onClick={()=>{dispatch(setModalsProps({...modalsProps,"modalCurrency" : false}));dispatch(setPropsOutput({...propsOutput,"currencyKey" : outputTitle["currencyKey"],"currencies" : outputTitle["currencies"]}))}}>Cancel</button>
                <button className={buttonOkLoad["GetOutput"] ? "button is-success is-loading" : "button is-success"} onClick={() => saveChanges(false)}>Save changes</button>
            </footer>
        </div>
        </div>
     : <></>
     }</>);
}
export default ModalCurrency;