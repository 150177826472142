import { createSlice } from "@reduxjs/toolkit";

export const mySlice = createSlice({
  name: "portal",
  initialState:
  {
    theCatalog : undefined,theOutput : "",catalogDetails : "",progressBarValue : 100,Icon : "",actionToDo : "",
    outputKind : "",listOfFirstTitle : {},inProgressCata : false,
    stickyAry : [],catalogResFilt : {},renameOutput : "",tempOutput : "",tabMenuList : {}
  },
  reducers: 
  {
    setTheCatalog(state,action){ state.theCatalog = action.payload;},
    setTheOutput(state,action){  state.theOutput = action.payload;},
    setCatalogDetails(state,action){  state.catalogDetails = action.payload;},
    setProgressBarValue(state,action){  state.progressBarValue = action.payload;},
    setIcon(state,action){  state.Icon = action.payload;},
    setActionToDo(state,action){  state.actionToDo = action.payload;},
    setOutputKind(state,action){  state.outputKind = action.payload;},
    setListOfFirstTitle(state,action){  state.listOfFirstTitle[0] = action.payload[1];},
    setInProgressCata(state,action){state.inProgressCata = action.payload;},
    setStickyAry(state,action){state.stickyAry[action.payload] = !state.stickyAry[action.payload]},
    setStickyAryAll(state,action){ state.stickyAry = action.payload;},
    setCatalogResFilt(state,action){state.catalogResFilt = action.payload;},
    setRenameOutput(state,action){state.renameOutput = action.payload;},
    setTabMenuList(state,action){state.tabMenuList = action.payload;},
    setTempOutput(state,action){state.tempOutput = action.payload;},
  }
});

export const {setTheCatalog
  ,setTheOutput,setDisabledTabs,setCatalogDetails,setProgressBarValue,setIcon
  ,setActionToDo,setOutputKind,setListOfFirstTitle,setTempOutput
  ,setInProgressCata,setCurrTableIsFullH,setStickyAry,setStickyAryAll,setCatalogResFilt
  ,setPageNav,setRenameOutput,setTabMenuList} = mySlice.actions;
export default mySlice.reducer;
