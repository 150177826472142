import { createSlice } from "@reduxjs/toolkit";

export const mySlice = createSlice({
  name: "makeTable",
  initialState:
  {
    generateTab : false,
  },
  reducers: 
  {
    setGenerateTab(state,action){state.generateTab = action.payload;},
  }
});

export const {setGenerateTab} = mySlice.actions;
export default mySlice.reducer;
